<template>
    <OrthographicWrapper :visible="visible">
        <PlayerNumber ref="refNumber" :number="playerNumber" :layer="LAYER_BG" />

        <PlayerName
            ref="refFirstName"
            :text="playerName.first"
            :fontSize="fontSize"
            :layer="LAYER_FG"
            :dir="1"
        />
        <PlayerName
            ref="refLastName"
            :text="playerName.last"
            :fontSize="fontSize"
            :layer="LAYER_FG"
            :dir="-1"
        />
    </OrthographicWrapper>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';

    import { useThreeObject, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import PlayerName from './PlayerName.vue';
    import PlayerNumber from './PlayerNumber.vue';
    import { useBounds } from '~/components/gl/Bounds';
    import OrthographicWrapper from '~/components/gl/common/OrthographicWrapper';
    import { LAYER_BG, LAYER_FG, TOTAL_DUR } from '~/core/constants';
    import { useAudio } from '~/providers/AudioProvider';

    const props = defineProps({
        playerName: {
            default: {
                first: 'First',
                last: 'Name',
            },
        },
        playerNumber: { default: 99 },
    });

    const audio = useAudio();

    const refNumber = ref(null);
    const refFirstName = ref(null);
    const refLastName = ref(null);

    const visible = ref(false);

    // const bounds = useBounds();
    const viewport = useViewportResize();
    const fontSize = computed(() => viewport.height * 0.18);

    useThreeObject(null, { name: 'PlayerInfo', props: { v: visible } });

    const show = ({ delay = 0 } = {}) => {
        const totalDuration = TOTAL_DUR;
        const tl = new gsap.timeline({
            delay,
            onStart: () => {
                visible.value = true;
                console.log('🚀 ~ PlayerInfo: animation start!');
                audio.play('playerInfoReveal');
            },
            onComplete: () => {
                visible.value = false;
                console.log('🚀 ~ PlayerInfo: animation complete!');
            },
        });
        tl.add(() => {
            refNumber.value.show();
            refFirstName.value.show();
            refLastName.value.show();
        }, 0);
        tl.add(() => {}, totalDuration);

        return tl;
    };

    const setFontSize = () => {
        const { object: firstNameObject } = refFirstName.value;
        const { object: lastNameObject } = refLastName.value;

        firstNameObject.position.y = fontSize.value * 0.4;
        lastNameObject.position.y = fontSize.value * -0.4;
    };

    watch([fontSize, refFirstName], setFontSize);

    defineExpose({ show });
</script>
