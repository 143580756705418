<template>
    <div>
        <GLText
            ref="refText"
            name="PlayerName"
            :text="text"
            :layer="layer"
            :options="{ fontSize }"
            @sync="onTextSync"
        ></GLText>
        <slot />
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';

    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import { useBounds } from '~/components/gl/Bounds';
    import GLText from '~/components/gl/GLText.vue';
    import { LAYER_BG, OUT_START, TOTAL_DUR } from '~/core/constants';

    const props = defineProps({
        text: { default: 'First Name' },
        fontSize: { default: 10 },
        layer: { default: LAYER_BG },
        dir: { default: 1 },
    });

    const refText = ref(null);
    const { object } = useThreeObject(null, { name: 'PlayerName' });
    const { object: innerObject } = useThreeObject(null, {
        name: 'PlayerNameInner',
        addToParent: false,
    });

    object.add(innerObject);

    const size = computed(() => refText.value.realSize);
    const bounds = useBounds();

    const onTextSync = () => {
        const { letters } = refText.value;

        letters.forEach((letter) => {
            letter.alpha = 0;
        });

        updateLetters();
    };

    const updateLetters = () => {
        refText.value.updateLetters();
    };

    const show = ({ delay = 0 } = {}) => {
        const { letters } = refText.value;
        const { dir } = props;

        const scale = 800 / bounds.height;

        const tl = gsap.timeline({ delay, onUpdate: updateLetters });
        const ambientDist = 80 * scale;
        const ambientDuration = TOTAL_DUR;

        // Main animation
        tl.fromTo(
            innerObject.position,
            { x: ambientDist * dir },
            { x: ambientDist * -dir, duration: ambientDuration, ease: 'none' },
            0
        );

        const lettersArr = dir < 0 ? letters.slice().reverse() : letters;
        // Letter animation
        const letterDist = 200 * scale;
        lettersArr.forEach((letter, i) => {
            const inDelay = i * 0.02;
            const outDelay = OUT_START + i * 0.02;

            tl.fromTo(
                letter.position,
                { x: letterDist * dir },
                { x: 0, duration: 1, ease: 'power2.out' },
                inDelay
            );
            tl.fromTo(letter, { alpha: 0 }, { alpha: 1, duration: 0.2, ease: 'sine.out' }, inDelay);

            tl.to(
                letter.position,
                { x: -letterDist * dir, duration: 0.7, ease: 'power2.in' },
                outDelay
            );
            tl.to(letter, { alpha: 0, duration: 0.5, ease: 'sine.in' }, outDelay);
        });
    };

    defineExpose({ show, object, size });
</script>
