<template>
    <slot />
</template>

<script setup>
    import { useThreeOrthoObject } from '#imports';
    import { Mesh, PlaneGeometry } from 'three';
    import { computed, onMounted, reactive, watch } from 'vue';

    import { useRafBool, useThreeObject, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import SpaceMaterial from './SpaceMaterial';
    import { LAYER_BG } from '~/core/constants';
    import { useNoiseTextures } from '~/providers/NoiseTexturesProvider';

    const props = defineProps({
        visible: { default: false, type: Boolean },
    });

    const propsReactive = reactive({
        starsReveal: 0,
        starsOffset: { x: 0, y: 0 },

        planetReveal0: 0,
        planetReveal1: 0,

        planetOffset: { x: 0, y: 1 },
    });

    const visible = computed(() => props.visible);
    const viewport = useViewportResize();

    const { object } = useThreeOrthoObject(null, {
        name: 'LightsDownSpace',
        props: { v: visible },
    });
    const { generateVoronoi } = useNoiseTextures();

    const shader = new SpaceMaterial({});

    const mesh = new Mesh(new PlaneGeometry(1, 1), shader);
    mesh.name = 'MeshSpace';
    mesh.layers.set(LAYER_BG);
    object.add(mesh);

    useRafBool(visible, ({ timestamp }) => {
        shader.u_time = timestamp / 1000;
    });

    onMounted(() => {
        shader.tVoronoi = generateVoronoi({ scale: 160 }).value;
        shader.defines.USE_VORONOI_TEXTURE = shader.tVoronoi !== null;
    });

    let tl;
    const show = ({ delay = 0 } = {}) => {
        propsReactive.starsReveal = 0;
        propsReactive.starsOffset = { x: 0, y: 0 };

        propsReactive.planetReveal0 = 0;
        propsReactive.planetReveal1 = 0;
        propsReactive.planetOffset = { x: 0, y: 1.4 };

        tl?.kill();
        tl = gsap
            .timeline({ delay })
            .to(propsReactive, { starsReveal: 1, duration: 2, ease: 'linear.none' }, 0)
            .to(propsReactive, { planetReveal0: 0.7, duration: 2, ease: 'sine.inOut' }, 0)
            .to(propsReactive, { planetReveal1: 0.3, duration: 4, ease: 'linear.none' }, 0)
            .to(propsReactive.starsOffset, { y: -4, duration: 3, ease: 'sine.inOut' }, 0)
            .to(propsReactive.starsOffset, { y: 25, duration: 4, ease: 'sine.inOut' }, 2)
            .to(propsReactive.planetOffset, { y: 3, duration: 4, ease: 'sine.in' }, 2)
            .to(propsReactive, { planetReveal0: 0.4, duration: 2, ease: 'sine.in' }, 2);
    };

    watch(
        propsReactive,
        ({ starsReveal, starsOffset, planetReveal0, planetReveal1, planetOffset }) => {
            shader.u_stars_reveal = starsReveal;
            shader.u_stars_offset.set(starsOffset.x, starsOffset.y);

            shader.u_planet_reveal = planetReveal0 + planetReveal1;
            shader.u_planet_offset.set(planetOffset.x, planetOffset.y);
        },
        { immediate: true }
    );

    watch(
        viewport,
        ({ width, height }) => {
            mesh.scale.set(width, height, 1);
            shader.u_resolution.set(width, height);
        },
        { immediate: true }
    );

    defineExpose({ show });
</script>
