<template>
    <OrthographicWrapper :visible="visible">
        <TextLine
            ref="refLines"
            v-for="(data, i) in texts"
            :text="text"
            :layer="LAYER_BG"
            :bounds="textBounds"
            :position="data.position"
            :key="`textLine${i}`"
            :debug="false"
        />
        <BackgroundGradient ref="refBgGradient" />
    </OrthographicWrapper>

    <Sticker
        ref="refSticker"
        :url="gradeBadge"
        :pos="{ x: 0.27, y: -0.05, z: 0.11 }"
        :scale="0.45"
    ></Sticker>

    <SerialNumber
        ref="refSerialNumber"
        :position="{ x: 0.1, y: 0.16, z: 0.25 }"
        :scale="0.2"
        :number="serialNumber"
        :total="serialTotal"
    ></SerialNumber>
</template>

<script setup>
    import { computed, ref } from 'vue';

    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import BackgroundGradient from '../../BackgroundGradient.vue';
    import Sticker from '../../Sticker.vue';
    import SerialNumber from './SerialNumber.vue';
    import TextLine from './TextLine.vue';
    import { useBounds } from '~/components/gl/Bounds';
    import OrthographicWrapper from '~/components/gl/common/OrthographicWrapper';
    import { LAYER_BG, TOTAL_DUR } from '~/core/constants';
    import { useAudio } from '~/providers/AudioProvider';

    const props = defineProps({
        text: { default: 'Team Info' },
        serialNumber: { default: 1 },
        serialTotal: { default: 99 },
        gradeBadge: { default: '/textures/card/psa-test@lg.webp' },
    });

    const totalLines = 4;

    const texts = ref([]);
    const refLines = ref([]);
    const refSticker = ref(null);
    const refSerialNumber = ref(null);
    const refBgGradient = ref(null);

    const visible = ref(false);

    useThreeObject(null, { name: 'CardInfo', props: { v: visible } });
    // useThreeObject(null, { name: 'CardInfo' });
    const audio = useAudio();
    const bounds = useBounds((val) => resize(val));

    const textBounds = computed(() => {
        const { width, height } = bounds;
        const textHeight = height / totalLines;
        const textWidth = width;
        return { width: textWidth, height: textHeight };
    });

    texts.value = new Array(totalLines).fill().map((_, i) => ({ position: { x: 0, y: 0 } }));

    const show = ({ delay = 0 } = {}) => {
        const totalDuration = TOTAL_DUR;
        const tl = new gsap.timeline({
            delay,
            onStart: () => {
                visible.value = true;
                console.log('🚀 ~ CardInfo: animation start!');

                audio.play('cardInfoReveal');
            },
            onComplete: () => {
                visible.value = false;
                console.log('🚀 ~ CardInfo: animation complete!');
            },
        });
        tl.add(() => {
            refLines.value?.forEach((line, i) => {
                const index = refLines.value.length - i - 1;
                line.show({ delay: index * 0.1 });
            });

            refBgGradient.value?.show({ delay: 0.1 });

            refSticker.value?.show({ delay: 0.2 });
            refSerialNumber.value?.show({ delay: 0.4 });
        }, 0);
        tl.add(() => {}, totalDuration);

        return tl;
    };

    const resize = ({ width, height }) => {
        texts.value.forEach((text, i) => {
            text.position.x = -width / 2;
            text.position.y = height / 2 - i * textBounds.value.height;
        });
    };

    defineExpose({ show });
</script>
