<template>
    <GLText
        ref="refNumber"
        :text="text"
        :layer="layer"
        :options="textOptions"
        @sync="onTextSync"
    ></GLText>
</template>

<script setup>
    import { Mesh, Object3D, TextureLoader } from 'three';
    import { computed, onBeforeUnmount, reactive, ref, watchEffect } from 'vue';

    import { lerp } from '@resn/gozer-math';
    import { simpleMaterial, simpleMaterialMap, simplePlaneGeometry } from '@resn/gozer-three';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, OUT_START } from '~/core/constants';

    const props = defineProps({
        debug: { default: false },
        layer: { default: 0 },
        year: { default: 2014 },
        scale: { default: 1 },
        position: { default: { x: 0, y: 0, z: 0.1 } },
    });

    const tweens = { pr: 0 };

    const refNumber = ref(null);

    const text = computed(() => `${props.year}`);

    const { object } = useThreeObject(null, { name: 'TeamYear' });
    const { object: innerObject } = useThreeObject(null, {
        name: 'PlayerNameInner',
        addToParent: false,
    });
    object.add(innerObject);

    const bgMap = new TextureLoader().load('/textures/card/team-year-bg@lg.webp');

    // const bg = new Mesh(simplePlaneGeometry, simpleMaterialMap(bgMap));
    const bg = new Mesh(
        simplePlaneGeometry,
        new CustomMaterial({
            uniforms: {
                uMap: bgMap,
                uAlpha: 1,
            },
            fs: `
            varying vec2 vUv;
            uniform float uAlpha;
            uniform sampler2D uMap;

            void main() {
                vec4 tex = texture2D(uMap, vUv);
                float alpha = tex.a * uAlpha;
                
                gl_FragColor = vec4(tex.rgb, alpha);
            }
        `,
            options: { depthTest: false, name: 'TeamYear' },
        })
    );
    const aspect = 110 / 33;
    bg.scale.set(1, 1 / aspect, 1);
    bg.position.z = -0.01;
    // bg.renderOrder = 2;
    bg.layers.set(props.layer);
    innerObject.add(bg);
    innerObject.position.y = -(1 / aspect) / 2;

    const textOptions = reactive({
        fontSize: 0.2,
        font: '/fonts/abc-diatype/ABCDiatypeMono-Medium-Trial.woff',
        color: 'black',
    });

    const onTextSync = () => {
        refNumber.value.object.position.set(-0.16, -0.02, 0);

        onUpdate();
    };

    const onUpdate = () => {
        innerObject.scale.setScalar(lerp(tweens.pr, 0.7, 1));

        const alpha = tweens.pr > 0 ? 1 : 0;

        bg.material.uniforms.uAlpha.value = alpha;
        refNumber.value.letters.forEach((letter) => {
            letter.alpha = alpha;
        });

        refNumber.value.updateLetters();
    };

    const show = ({ delay = 0 } = {}) => {
        const tl = gsap.timeline({ delay, onUpdate });

        tl.fromTo(tweens, { pr: 0 }, { pr: 1, duration: 0.8, ease: 'power3.out' }, 0.3);
        tl.to(tweens, { pr: 0, duration: 0.25, ease: 'power2.in' }, OUT_START - 0.05);
    };

    const setProps = () => {
        const { x, y, z } = props.position;
        object.position.set(x, y, z);
        object.scale.setScalar(props.scale);
        object.rotation.z = Math.PI * 0.5;
    };

    onBeforeUnmount(() => {
        bg.geometry.dispose();
        bg.material.dispose();
        bgMap.dispose();
    });

    watchEffect(setProps);

    defineExpose({ show });
</script>
