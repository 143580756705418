<template>
    <Space ref="refSpace" :visible="visible" />
    <!-- <Particles ref="refParticles" :visible="visible" /> -->
    <!-- <Aurora1></Aurora1> -->
    <Aurora2 ref="refAurora"></Aurora2>
</template>

<script setup>
    import { useThreeOrthoObject } from '#imports';
    import { ref } from 'vue';

    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Aurora1 from './Auroa1/Aurora1.vue';
    import Aurora2 from './Aurora2/Aurora2.vue';
    import Particles from './Particles.vue';
    import Space from './Space.vue';
    import { LIGHTS_DOWN_DUR, LIGHTS_DOWN_REVEAL_START } from '~/core/constants';
    import { useAudio } from '~/providers/AudioProvider';

    const props = defineProps({});

    const refSpace = ref();
    const refParticles = ref();
    const refAurora = ref();
    const audio = useAudio();

    const visible = ref(false);
    useThreeObject(null, { name: 'LightsDown' });

    const show = ({ delay = 0 } = {}) => {
        const tl = new gsap.timeline({
            delay,
            onStart: () => {
                visible.value = true;
                // console.log('🚀 ~ Space: animation start!');
                emit('start');

                audio.play('lightsDownReveal');
            },
            onComplete: () => {
                visible.value = false;
                // console.log('🚀 ~ Space: animation complete!');
                emit('complete');
            },
        });
        tl.add(() => refSpace.value?.show(), 1.8);
        tl.add(() => refParticles.value?.show(), LIGHTS_DOWN_REVEAL_START);
        tl.add(
            () =>
                refAurora.value?.show({
                    start: LIGHTS_DOWN_REVEAL_START,
                    end: LIGHTS_DOWN_DUR - 1,
                }),
            0
        );
        tl.add(() => {}, LIGHTS_DOWN_DUR);

        return tl;
    };

    defineExpose({ show });

    const emit = defineEmits(['start', 'complete']);
</script>
