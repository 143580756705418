<template>
    <GLText
        ref="refTextBase"
        :text="text"
        :layer="LAYER_BG"
        :options="textOptions"
        :visible="false"
    ></GLText>
    <GLText
        ref="refText"
        name="TextLine"
        :text="finalText"
        :layer="LAYER_BG"
        :options="textOptions"
        @sync="onTextSync"
        v-if="finalText"
    ></GLText>
</template>

<script setup>
    import { Mesh } from 'three';
    import { computed, reactive, ref, watch, watchEffect } from 'vue';

    import { clamp } from '@resn/gozer-math';
    import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, LAYER_BG, OUT_DUR, OUT_START, TOTAL_DUR } from '~/core/constants';
    import { useColorPalette } from '~/providers/ColorPaletteProvider';
    import { useFanatics } from '~/providers/FanaticsProvider';
    import { darkenHexColor } from '~/utils/darken-hex-color';

    const props = defineProps({
        debug: { default: false },
        layer: { default: LAYER_BG },
        text: { default: 'Team Info' },
        bounds: { default: { width: 1, height: 1 } },
        position: { default: { x: 0, y: 0 } },
    });

    const refText = ref(null);
    const refTextBase = ref(null);
    const ready = ref(false);

    const finalText = computed(() => {
        const baseTextWidth = refTextBase.value?.baseSize.width;
        const { width } = props.bounds;

        if (!baseTextWidth || !width) return null;
        const iterations = Math.ceil(width / baseTextWidth);

        return props.text.repeat(iterations);
    });

    const { object } = useThreeObject(null, { name: 'TextLine' });
    const { object: innerObject } = useThreeObject(null, {
        name: 'TextLineInner',
        addToParent: false,
    });

    // const colorPalette = useColorPalette();
    const { colorPalette } = useFanatics();

    const bg = new Mesh(simplePlaneGeometry, simpleMaterial('red'));
    bg.layers.set(props.layer);
    bg.material.transparent = true;
    bg.material.opacity = 0.3;

    object.add(innerObject);
    innerObject.add(bg);

    const fontSize = computed(() => props.bounds.height * 1.25);

    const textOptions = reactive({
        fontSize,
        anchorX: 'left',
        anchorY: 'top',
        color: computed(() => darkenHexColor(colorPalette.value[1], 0.7)),
    });

    const onTextSync = () => {
        const { letters } = refText.value;

        letters.forEach((letter) => {
            letter.alpha = 0;
        });

        updateLetters();
    };

    const updateLetters = () => {
        refText.value.updateLetters();
    };

    const show = ({ delay = 0 } = {}) => {
        const { object, realSize, letters } = refText.value;
        const { bounds } = props;

        const scale = 800 / bounds.height;

        const tl = gsap.timeline({ delay, onUpdate: updateLetters });
        const xFrom = props.bounds.width;
        const xTo = -realSize.width;

        const ambientDist = 80 * scale;
        // const ambientDist = clamp(realSize.width - props.bounds.width, 0, 100 * scale);

        const ambientDuration = TOTAL_DUR;
        tl.fromTo(
            innerObject.position,
            { x: 0 },
            { x: -ambientDist, duration: ambientDuration, ease: 'none' },
            0
        );
        tl.fromTo(object.position, { x: xFrom }, { x: 0, duration: IN_DUR, ease: 'power3.out' }, 0);
        tl.to(object.position, { x: xTo, duration: OUT_DUR, ease: 'power3.in' }, OUT_START);

        const letterDist = bounds.width * 0.2;
        letters.forEach((letter, i) => {
            const inDelay = i * 0.02;
            const outDelay = OUT_START + i * 0.02;

            tl.fromTo(
                letter.position,
                { x: letterDist },
                { x: 0, duration: 1, ease: 'power2.out' },
                inDelay
            );
            tl.fromTo(letter, { alpha: 0 }, { alpha: 1, duration: 0.2, ease: 'sine.out' }, inDelay);

            tl.to(letter.position, { x: -letterDist, duration: 0.7, ease: 'power2.in' }, outDelay);
            // tl.to(letter, { alpha: 0, duration: 0.5, ease: 'sine.in' }, outDelay);
            tl.set(letter, { alpha: 0 });
        });
    };

    const setFontSize = (val) => {
        // console.log('🚀 ~ setFontSize:', fontSize.value);
        innerObject.position.y = fontSize.value * 0.05;
    };

    const setPosition = () => {
        const { x, y } = props.position;
        object.position.set(x, y, 0);
        // console.log('🚀 ~ setPosition ~ position:', props.position);
    };

    const setBounds = () => {
        const { width, height } = props.bounds;
        // console.log('🚀 ~ setBounds ~ width, height:', width, height);
        const bgHeight = height * 0.95;
        bg.scale.set(width, bgHeight, 1);
        bg.position.set(width / 2, -bgHeight / 2, 0);
    };

    const setDebug = () => {
        bg.visible = props.debug;
    };

    watchEffect(setPosition);
    watchEffect(setBounds);
    watchEffect(setDebug);
    watchEffect(setFontSize);

    defineExpose({ show });
</script>
