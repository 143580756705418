<template>
    <GLText
        ref="refText"
        name="PlayerNumber"
        :text="numberString"
        :layer="layer"
        :options="textOptions"
        @sync="onTextSync"
    ></GLText>
</template>

<script setup>
    import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';

    import { useThreeObject, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import { useBounds } from '~/components/gl/Bounds';
    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, LAYER_BG, OUT_START, PAUSE_DUR } from '~/core/constants';

    const props = defineProps({
        number: { default: 99 },
        layer: { default: LAYER_BG },
    });

    const refText = ref(null);
    const numberString = computed(() => String(props.number));

    // const bounds = useBounds();
    const viewport = useViewportResize();
    const fontSize = computed(() => viewport.height * 0.75);
    // const fontSize = computed(() => 500);

    const textOptions = reactive({
        fontSize,
        strokeWidth: 1,
        // fontSize: 1.4,
        // strokeWidth: 0.003,
        font: '/fonts/inter/Inter_18pt-Black.ttf',
        fillOpacity: 0,
        letterSpacing: -0.05,
        strokeColor: 'white',
        strokeOpacity: 0.2,
    });

    useThreeObject(null, { name: 'PlayerNumber' });
    // object.position.z = -0.2;

    const onTextSync = () => {
        const { letters } = refText.value;
        letters.forEach((letter) => {
            letter.alpha = 0;
        });

        updateLetters();
    };

    const updateLetters = () => {
        refText.value.updateLetters();
    };

    const onUpdate = () => {
        updateLetters();
    };

    const show = ({ delay = 0 } = {}) => {
        const tl = new gsap.timeline({ delay, onUpdate });

        const { letters } = refText.value;
        letters.forEach((letter, index) => {
            const inDelay = index * 0.2;
            const outDelay = OUT_START + index * 0.2;
            tl.fromTo(letter, { alpha: 0 }, { alpha: 1, duration: 1 }, inDelay);
            tl.to(letter, { alpha: 0, duration: 0.5 }, outDelay);
        });
    };

    defineExpose({ show });
</script>
