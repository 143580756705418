import { Color, ShaderMaterial, Vector2 } from 'three';

import { materialEasyUniforms, simpleVs } from '@resn/gozer-three';

import glslBlend from '@/libs/lygia/color/blend.glsl';
import glslDesaturate from '@/libs/lygia/color/desaturate.glsl';
import glslPaletteSpectral from '@/libs/lygia/color/palette/spectral.glsl';
import glslGamma2linear from '@/libs/lygia/color/space/gamma2linear.glsl';
import glslCircle from '@/libs/lygia/draw/circle.glsl';
import glslVoronoi from '@/libs/lygia/generative/voronoi.glsl';
import glslAastep from '@/libs/lygia/math/aastep.glsl';
import glslRatio from '@/libs/lygia/space/ratio.glsl';
import glslScale from '@/libs/lygia/space/scale.glsl';

class SpaceMaterial extends ShaderMaterial {
    constructor(props) {
        props = Object.assign(
            {
                vertexShader,
                fragmentShader,
                type: 'SpaceMaterial',
                defines: {
                    USE_PLANET: true,
                    USE_VORONOI_TEXTURE: false,
                    USE_DEBUG: false,
                },
            },
            props
        );
        super(props);

        const uniforms = {
            tVoronoi: { value: null },

            u_time: { value: 0 },
            u_resolution: { value: new Vector2() },

            u_stars_reveal: { value: 1 },
            u_stars_offset: { value: new Vector2() },

            u_planet_reveal: { value: 1 },
            u_planet_offset: { value: new Vector2(0, 0.7) },

            u_color0: { value: new Color('#dea439').convertLinearToSRGB() },
            u_color1: { value: new Color('#ce7040').convertLinearToSRGB() },
        };
        this.uniforms = { ...uniforms, ...this.uniforms };
        materialEasyUniforms(this, this.uniforms);
    }
}
export default SpaceMaterial;

const vertexShader = simpleVs;
const fragmentShader = /* glsl */ `
#ifdef GL_ES
precision mediump float;
#endif

varying vec2 vUv;

uniform sampler2D tVoronoi;

uniform float u_time;
uniform vec2 u_resolution;

uniform float u_stars_reveal;
uniform vec2 u_stars_offset;

#if !defined(USE_DEBUG) 
uniform float u_planet_reveal;
uniform vec2 u_planet_offset; 

uniform vec3 u_color0;
uniform vec3 u_color1;
#else
float u_planet_reveal = 1.0;
// float u_planet_reveal = abs(sin(u_time * 0.5));
const vec2 u_planet_offset = vec2(0.0, 1.7);

// vec3 u_color0 = vec3(0.78, 0.92, 0.91);
// vec3 u_color1 = vec3(0.5922, 0.698, 0.8627);

// uniform vec3 u_color0;
// uniform vec3 u_color1;
vec3 u_color0 = vec3(0.9137, 0.6314, 0.0235);
vec3 u_color1 = vec3(0.8667, 0.4157, 0.1922);
#endif

${glslRatio}
${glslCircle}
${glslScale}
${glslVoronoi}
${glslAastep}
${glslBlend}
${glslDesaturate}
${glslGamma2linear}
${glslPaletteSpectral}

vec4 cheapAtmosphereScattering(in float sdf, in float r) {
    
    vec3 col1 = u_color0;
    vec3 col2 = u_color1;
    
    float R0 = mix(0.3, - 0.5, r);
    float R1 = 0.316;
    float R2 = 0.32;
    float R3 = mix(0.4, 1.0, r);
    
    if (sdf < R1) {
        float transition = pow(max(0.0, (sdf - R0) / (R1 - R0)), 2.0);
        return vec4(col1 * transition, 1.0);
    }
    if (sdf < R2) {
        float transition = pow((sdf - R1) / (R2 - R1), 2.0);
        return vec4(mix(col1, col2, transition), 1.0);
    }
    
    float transition = pow(max(0.0, 1.0 - (sdf - R2) / (R3 - R2)), 2.0);
    return vec4(col2 * transition, 1.0);
}

vec4 getPlanet(vec2 st, float r) {
    
    vec2 stAtm = st;
    stAtm = scale(stAtm, vec2(0.6, 1));
    stAtm.y += mix(0.5, 0.0, r);
    
    vec2 stOcc = st;
    stOcc = scale(stOcc, vec2(0.6, 1));
    
    vec2 stSun = st;
    stSun.y += mix(0.4, - 0.6, r);
    stSun = scale(stSun, vec2(0.4, 0.24));
    
    float sizeOcc = mix(2.0, 0.5, r);
    
    float sdfOcc = fill(circleSDF(stOcc), sizeOcc, 0.5) * 1.0; // occlusion
    float sdfAtm = fill(circleSDF(stAtm), 1.5, 2.0) * 2.0; // atmosphere
    float sdfAtmOcc = fill(circleSDF(stAtm), 2.45, 0.1) * 1.0; // occlusion planet
    float sdfSun = fill(circleSDF(stSun), 0.4, 0.2) * 1.0; // sun
    
    float sdSpectral = smoothstep(0.26, 0.36, sdfAtm);
    float amtSpectral = (sdSpectral - sdfSun) - sdfOcc;
    
    vec3 cSun = vec3(0.9098, 0.6863, 0.5059);
    
    vec3 res;
    res = cheapAtmosphereScattering(sdfAtm, r).rgb * (1.0 - sdfOcc);
    res = blendVividLight(res, cSun, (sdfSun * 1.6) * (1.0 - sdfAtmOcc));
    res = gamma2linear(res);
    res = desaturate(res, 0.66);
    res = blendScreen(res, spectral(sdSpectral, 0.2), amtSpectral);
    
    // res = vec3(sdfSun);
    // res = vec3(spectral(smoothstep(0.3, 0.34, sdfAtm)));
    // res = vec3(res + sdfAtmOcc);
    // res = vec3(sdfAtmOcc);
    
    return vec4(res, sdfAtmOcc);
}

vec4 inverseSRGBTransferOETF( in vec4 value ) {
    return vec4( mix( pow( ( value.rgb + vec3( 0.055 ) ) / 1.055, vec3( 2.4 ) ), value.rgb / 12.92, vec3( lessThanEqual( value.rgb, vec3( 0.04045 ) ) ) ), value.a );
}

void main(void) {
    vec4 color = vec4(vec3(0.0), 1.0);
    
    vec2 uv = vUv;
    vec2 st = ratio(uv, u_resolution);
    vec2 stN = st;

    vec3 d3;
    #ifdef USE_VORONOI_TEXTURE
        stN = scale(st, 0.5);
        stN += u_stars_offset / 140.;
        stN.y = mod(stN.y, 1.0);

        d3 = texture2D(tVoronoi, stN).xyz;
    #else
        stN = scale(st, 80.0);
        stN += u_stars_offset;
        stN.y += u_time * 0.01;
        
        d3 = vec3(voronoi(stN));
    #endif

    float d3Dist = d3.z;
    float d3Luma = dot(d3, vec3(0.299, 0.587, 0.114));
    
    float dMultAdd = d3Luma * -26.0 - (mix(1.0, 5.0, 1.0 - u_stars_reveal));
    float dThresh = exp(dMultAdd) * 1.0;
    float dLessThan = aastep(d3Dist, dThresh);
    
    float dMask = length(uv - 0.5);
    vec3 resStars = smoothstep(0.7, 0.0, dMask) * vec3(dLessThan);

    #ifdef USE_PLANET
        vec2 stPlanet = ratio(vUv, u_resolution);
        stPlanet += u_planet_offset;
    
        vec4 resPlanetFull = getPlanet(stPlanet, u_planet_reveal);
        float sdfEclOcc = resPlanetFull.a;
        
        vec3 resPlanet = resPlanetFull.rgb;
        resPlanet = inverseSRGBTransferOETF(resPlanetFull).rgb;
    #endif
    
    color.rgb = blendAdd(resPlanet, resStars, 1. - smoothstep(0.1, 0.2, sdfEclOcc));
    
    gl_FragColor = color;
    #ifdef USE_DEBUG
        gl_FragColor = vec4(resPlanet, 1.0);
        // gl_FragColor = vec4(u_color1, 1.0);
    #endif
}
`;
