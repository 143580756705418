<template>
    <SimplePlane
        ref="refBg"
        :renderOrder="1"
        :material="material"
        :layer="LAYER_BG"
        :bounds="bounds"
    />
</template>

<script setup>
    import { onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';

    import glslSdfCircle from '@resn/gozer-glsl/sdf/sdCircle.glsl';
    import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
    import glslRoundRect from '@resn/gozer-glsl/shapes/round-rect.glsl';
    import { LoaderEvent } from '@resn/gozer-loading';
    import { lerp } from '@resn/gozer-math';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { usePane } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import { useBounds } from '../../Bounds';
    import SimplePlane from '../../common/SimplePlane.vue';
    import { LAYER_BG, OUT_START } from '~/core/constants';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const refBg = ref(null);
    const minSize = ref(0.9);
    const maxSize = ref(1.15);
    const progress = ref(0);
    const edgeSize = ref(0.3);

    const size = ref(minSize);
    const bounds = useBounds();

    onMounted(() => {
        refBg.value.mesh.position.z = 0.1;
    });

    const material = new CustomMaterial({
        uniforms: {
            uTime: 0,
            uNoiseMap: null,
            uSize: size.value,
            uEdgeSize: edgeSize.value,
        },
        fs: `
        uniform float uTime;
        uniform float uSize;
        uniform float uEdgeSize;
        uniform sampler2D uNoiseMap;

        varying vec2 vUv;

        ${glslDraw}
        ${glslSdfCircle}

        void main() {
            float noise = texture2D(uNoiseMap, vUv * 0.2).r;
            
            float sdf = sdCircle(vUv);
            float gradient = 1.0 - fill(sdf, uSize, uEdgeSize);
            
            float vignette = gradient;
            // vignette = gradient + mix(0,0, noise, uProgress);
            
            vec3 red = vec3(1.0, 0.0, 0.0);
            vec3 black = vec3(0.0);
            
            float alpha = vignette;
            gl_FragColor = vec4(black, alpha);
            // gl_FragColor = vec4(vec3(alpha), 1.0);
            // gl_FragColor = vec4(vUv, 1.0, 1.0);
            
            #include <tonemapping_fragment>
            #include <colorspace_fragment>
        }
    `,
        options: { name: 'BackgroundGradient' },
    });

    useGlobalAssets(({ data }) => {
        material.uniforms.uNoiseMap.value = data.noise1;
    });

    const show = ({ delay = 0 } = {}) => {
        const tl = gsap.timeline({ delay });
        tl.fromTo(progress, { value: 0 }, { value: 1, duration: 1.5, ease: 'power3.inOut' });
        tl.to(progress, { value: 0, duration: 1 }, OUT_START - delay);
    };

    watchEffect(() => {
        // material.uniforms.uSize.value = size.value;
        material.uniforms.uSize.value = lerp(progress.value, minSize.value, maxSize.value);
        material.uniforms.uEdgeSize.value = edgeSize.value;
    });

    const destroy = () => {
        material.dispose();
    };

    onBeforeUnmount(destroy);

    defineExpose({ show });

    usePane(
        [
            { name: 'progress', value: progress, options: { min: 0, max: 1, step: 0.01 } },
            { name: 'minSize', value: minSize, options: { min: 0, max: 2, step: 0.01 } },
            { name: 'maxSize', value: maxSize, options: { min: 0, max: 2, step: 0.01 } },
            { name: 'edgeSize', value: edgeSize, options: { min: 0, max: 2, step: 0.01 } },
        ],
        { title: 'Background Gradient' }
    );
</script>
