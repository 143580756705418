<template>
    <OrthographicWrapper :visible="visible">
        <PlayerName
            ref="refLine1"
            :text="teamName.line1"
            :fontSize="fontSize"
            :layer="LAYER_BG"
            :dir="1"
        />

        <!-- <TeamYear ref="refYear" :year="teamYear"></TeamYear> -->
        <PlayerName
            ref="refLine2"
            :text="teamName.line2"
            :fontSize="fontSize"
            :layer="LAYER_FG"
            :dir="-1"
        >
            <TeamYear
                ref="refYear"
                :year="teamYear"
                :scale="yearScale"
                :position="yearPos"
                :layer="LAYER_FG"
            ></TeamYear>
        </PlayerName>
    </OrthographicWrapper>
    <TeamBadge
        ref="refSticker"
        :url="teamBadge"
        :pos="{ x: 0.25, y: 0.3, z: 0.11 }"
        :scale="0.38"
    ></TeamBadge>
</template>

<script setup>
    import { computed, onMounted, ref, watch, watchEffect } from 'vue';

    import { useThreeObject, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import PlayerName from '../PlayerInfo/PlayerName.vue';
    import TeamBadge from './TeamBadge.vue';
    import TeamYear from './TeamYear.vue';
    import { useBounds } from '~/components/gl/Bounds';
    import OrthographicWrapper from '~/components/gl/common/OrthographicWrapper';
    import { LAYER_BG, LAYER_FG, TOTAL_DUR } from '~/core/constants';
    import { useAudio } from '~/providers/AudioProvider';

    const props = defineProps({
        teamName: {
            default: {
                line1: 'Line 1',
                line2: 'Line 2',
            },
        },
        teamBadge: { default: '/textures/card/team-badge@lg.webp' },
        teamYear: { default: 2014 },
    });

    const audio = useAudio();

    const refLine1 = ref(null);
    const refLine2 = ref(null);
    const refSticker = ref(null);
    const refYear = ref(null);

    const visible = ref(false);
    const viewport = useViewportResize();

    const fontSize = computed(() => viewport.height * 0.14);
    const linesReady = computed(() => refLine1.value && refLine2.value);

    const yearPos = computed(() => {
        const lineSize = refLine2.value?.size;

        let x = 0;
        let y = 0;
        if (lineSize) {
            x = lineSize.width / 2 + 10;
            y = lineSize.height * 0.05;
        }

        return { x, y, z: 0.1 };
    });

    const yearScale = computed(() => fontSize.value * 0.75);

    useThreeObject(null, { name: 'TeamInfo', props: { v: visible } });

    const show = ({ delay = 0 } = {}) => {
        const totalDuration = TOTAL_DUR;
        const tl = new gsap.timeline({
            delay,
            onStart: () => {
                visible.value = true;
                audio.play('teamInfoReveal');

                console.log('🚀 ~ TeamInfo: animation start!');
            },
            onComplete: () => {
                visible.value = false;
                console.log('🚀 ~ TeamInfo: animation complete!');
            },
        });
        tl.add(() => {
            refLine1.value.show();
            refLine2.value.show();
            refYear.value.show();
            refSticker.value.show({ delay: 0.2 });
        }, 0);
        tl.add(() => {}, totalDuration);

        return tl;
    };

    const setFontSize = () => {};

    const setViewport = () => {
        const { height } = viewport;

        if (linesReady.value) {
            const { object: line1 } = refLine1.value;
            const { object: line2 } = refLine2.value;

            line1.position.y = height * 0.25;
            line2.position.y = height * -0.25;
        }
    };

    watch([fontSize, refLine1], setFontSize);
    watch([viewport, linesReady], setViewport);

    defineExpose({ show });
</script>
