<template>
    <GLText
        ref="refNumber"
        :text="text"
        :layer="0"
        :renderOrder="5"
        :options="textOptions"
        name="SerialNumber"
    ></GLText>
</template>

<script setup>
    import { Mesh, Object3D } from 'three';
    import { computed, onBeforeUnmount, reactive, ref, watchEffect } from 'vue';

    import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
    import glslRoundRect from '@resn/gozer-glsl/shapes/round-rect.glsl';
    import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import GLText from '~/components/gl/GLText.vue';
    import { IN_DUR, LAYER_BG, OUT_START } from '~/core/constants';

    const props = defineProps({
        debug: { default: false },
        number: { default: 55 },
        total: { default: 99 },
        scale: { default: 1 },
        position: { default: { x: 0, y: 0, z: 0.02 } },
    });

    const tweens = { pr: 0 };

    const refNumber = ref(null);

    const text = computed(() => `${props.number}/${props.total}`);

    const { object } = useThreeObject(null, { name: 'SerialNumber' });
    const innerObj = new Object3D();
    innerObj.scale.setScalar(0);
    object.add(innerObj);
    useThreeObject(innerObj, { name: 'SerialNumber', addToParent: false });

    const shadow = new Mesh(
        simplePlaneGeometry,
        new CustomMaterial({
            fs: `
            varying vec2 vUv;
            
            ${glslDraw}
            ${glslRoundRect}
            
            void main() {
                float sdf = sdRect(vUv, vec2(1.5, 1.0), 1.0);
                float shadow = fill(sdf, 0.3, 0.3);
                vec3 color = vec3(0.0,0.0,0.0);
                
                gl_FragColor = vec4(color, shadow * 0.5);
                // gl_FragColor = vec4(vec3(shadow), 1.0);
                #include <tonemapping_fragment>
                #include <colorspace_fragment>
            }`,
        })
    );
    shadow.position.set(0.08, -0.05, -0.02);
    shadow.renderOrder = 5;
    innerObj.add(shadow);

    const bg = new Mesh(
        simplePlaneGeometry,
        new CustomMaterial({
            fs: `
            varying vec2 vUv;
            
            ${glslDraw}
            ${glslRoundRect}
            void main() {
                float shape = roundrect(vUv, vec2(2.0, 1.3), 1.3);
                vec3 color = vec3(0.05);
                
                gl_FragColor = vec4(color, shape);
                #include <tonemapping_fragment>
                #include <colorspace_fragment>
            }`,
            options: { depthWrite: false },
        })
    );
    bg.position.z = -0.01;
    bg.renderOrder = 5;
    innerObj.add(bg);

    const textOptions = reactive({
        fontSize: 0.37,
        letterSpacing: 0.03,
        font: '/fonts/manuka/Manuka-Bold.otf',
        color: 'white',
    });

    const onUpdate = () => {
        innerObj.scale.setScalar(tweens.pr);
    };

    const show = ({ delay = 0 } = {}) => {
        const tl = gsap.timeline({ delay, onUpdate });

        tl.fromTo(tweens, { pr: 0 }, { pr: 1, duration: 0.6, ease: 'power3.out' }, 0);
        tl.to(tweens, { pr: 0, duration: 0.5, ease: 'power2.in' }, OUT_START - delay);
    };

    const setProps = () => {
        const { x, y, z } = props.position;
        object.position.set(x, y, z);
        object.scale.setScalar(props.scale);
    };

    onBeforeUnmount(() => {
        shadow.geometry.dispose();
        shadow.material.dispose();
        bg.geometry.dispose();
        bg.material.dispose();
    });

    watchEffect(setProps);

    defineExpose({ show });
</script>
