<template>
    <div></div>
</template>

<script setup>
    import {
        ClampToEdgeWrapping,
        CylinderGeometry,
        DoubleSide,
        Mesh,
        PlaneGeometry,
        SRGBColorSpace,
        Texture,
        TextureLoader,
    } from 'three';
    import { onBeforeUnmount, ref, watchEffect } from 'vue';

    import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
    import glslRoundRect from '@resn/gozer-glsl/shapes/round-rect.glsl';
    import { lerp } from '@resn/gozer-math';
    import { simpleMaterialMap, simplePlaneGeometry } from '@resn/gozer-three';
    import { CustomMaterial } from '@resn/gozer-three/materials';
    import { usePane, useThreeObject } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import glslScale from '@/libs/lygia/space/scale.glsl';
    import { neutralTonemappingChunks } from '~/components/gl/shaders/ShaderChunks/neutral-tonemapping';
    import { IN_DUR, OUT_DUR, OUT_START, PAUSE_DUR } from '~/core/constants';
    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';

    const props = defineProps({
        url: { default: '/textures/card/psa-test@lg.webp' },
        shape: { default: 'round' }, // round, square
        pos: { default: { x: 0, y: 0, z: 0 } },
        scale: { default: 1 },
    });

    const visible = ref(false);
    const progressIn = ref(0);
    const progressOut = ref(0);
    const rotation = ref(0.25);
    const segments = ref(10);
    const peelRadius = ref(0.4);
    const peelRotZ = ref(1.32);
    const peelPosX = ref(0);
    const peelPosY = ref(0);

    const { object } = useThreeObject(null, { name: 'Sticker', props: { v: visible } });

    loadBitmapTexture(props.url).then((texture) => {
        // texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
        material.uniforms.uStickerTex.value = texture;
    });

    const geo = new PlaneGeometry(1, 1, segments.value, segments.value);
    const material = new CustomMaterial({
        uniforms: {
            uStickerTex: null,
            uProgress: 0,
            uPeelRadius: peelRadius.value,
            uPeelRotZ: peelRotZ.value,
            uPeelPosX: peelPosX.value,
            uPeelPosY: peelPosY.value,
        },
        vs: /* glsl */ `
            
			#define PI radians(180.0)

			uniform float uTime;

			uniform float uPeelRadius;
			uniform float uPeelRotZ;
			uniform float uPeelPosX;
			uniform float uPeelPosY;

			varying vec3 vNormal; 
		    varying vec3 vPosition; 
			varying vec2 vUv;

			void rotateAroundPointZ(inout vec3 p , vec3 c, float angle) {
				float si = sin(angle);
				float co = cos(angle);
				// translate point back to origin:
				p.x -= c.x;
				p.y -= c.y;
				// rotate point
				float Xnew = p.x * co - p.y * si;
				float Ynew = p.x * si + p.y * co;
				// // translate point back:
				p.x = Xnew + c.x;
				p.y = Ynew + c.y;
			}
			
            void main() {
				vUv = uv;

				float TAU = PI * 2.0; 
				float cylinderAngle = TAU * uPeelRotZ;
				vec2 cylinderPosition = vec2(uPeelPosX, uPeelPosY);

				vNormal = normal;
				vPosition = position;
				vPosition.xy += cylinderPosition;

				rotateAroundPointZ( vPosition, vec3(0., 0., 0.), -cylinderAngle);
				
				float L = vPosition.y;
				float circ = TAU * uPeelRadius;
				float unitCirc = L/circ;
				float a = (unitCirc * TAU);
				vec2 curve = vec2(uPeelRadius*cos(a), uPeelRadius*sin(a));
				float mul = step(0.0, L);
				
				vPosition.z = (curve.x - uPeelRadius)*mul;
				vPosition.y = mix( vPosition.y, curve.y, mul);
				
				rotateAroundPointZ( vPosition, vec3(0., 0., 0.), cylinderAngle);

				vPosition.xy -= cylinderPosition;
				vPosition.z = -vPosition.z;

				gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1);
				// gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
			}
        `,
        fs: `
            uniform sampler2D uStickerTex;
            varying vec2 vUv;

            ${glslDraw}
            ${glslRoundRect}
            ${glslScale}
            
            void main() {
                // vec4 stickerTex = texture2D(uStickerTex, scale(vUv, 1.4));
                vec4 stickerTex = texture2D(uStickerTex, vUv);
                
                gl_FragColor = stickerTex;
                
                #include <tonemapping_fragment>
                #include <colorspace_fragment>
            }
        `,
        options: { side: DoubleSide, name: 'Sticker' },
    });

    const mesh = new Mesh(geo, material);
    // const mesh = new Mesh(geo, simpleMaterialMap(texture));
    mesh.renderOrder = 5;
    mesh.position.set(0, 0, 0.2);

    object.add(mesh);

    const show = ({ delay = 0 } = {}) => {
        const tl = new gsap.timeline({
            delay,
            onStart: () => (visible.value = true),
            onComplete: () => (visible.value = false),
        });
        tl.fromTo(progressIn, { value: 0 }, { value: 1, duration: 1.5, ease: 'power3.out' });
        tl.fromTo(
            progressOut,
            { value: 0 },
            { value: 1, duration: 0.5, ease: 'power2.in' },
            OUT_START
        );
    };
    const hide = () => {};

    const setProgress = () => {
        if (mesh.material.uniforms) {
            mesh.material.uniforms.uProgress.value = progressIn.value;
            mesh.material.uniforms.uPeelRadius.value = peelRadius.value;
            mesh.material.uniforms.uPeelRotZ.value = peelRotZ.value;
            mesh.material.uniforms.uPeelPosX.value = peelPosX.value;
            mesh.material.uniforms.uPeelPosY.value = peelPosY.value;

            const peelx = lerp(progressIn.value, -1, 0.5);
            const peely = lerp(progressIn.value, 0, 0);
            mesh.material.uniforms.uPeelPosX.value = peelx;
            mesh.material.uniforms.uPeelPosY.value = peely;
        }

        object.rotation.z = rotation.value;
        object.scale.setScalar(props.scale);
        object.position.set(props.pos.x, props.pos.y, props.pos.z);

        const outScale = lerp(progressOut.value, 1, 0);
        mesh.scale.setScalar(outScale);
    };

    const destroy = () => {
        material.dispose();
        material.uniforms.uStickerTex.value?.dispose();
        geo.dispose();
    };

    onBeforeUnmount(destroy);

    watchEffect(setProgress);

    defineExpose({ show, hide, mesh });

    usePane(
        [
            { name: 'prIn', value: progressIn, options: { min: 0, max: 1, step: 0.01 } },
            { name: 'rotation', value: rotation, options: { min: -3.14, max: 3.14, step: 0.01 } },
            { name: 'peelRadius', value: peelRadius, options: { min: 0, max: 2, step: 0.01 } },
            { name: 'peelRotZ', value: peelRotZ, options: { min: 0, max: 3.14, step: 0.01 } },
            { name: 'peelPosX', value: peelPosX, options: { min: -3, max: 3, step: 0.01 } },
            { name: 'peelPosY', value: peelPosY, options: { min: -3, max: 3, step: 0.01 } },
        ],
        { title: 'Sticker' }
    );
</script>
