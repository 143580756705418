import { Mesh } from 'three';
import { defineComponent, watchEffect } from 'vue';

import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
import { useThreeObject } from '@resn/gozer-vue';

import { useBounds } from '../Bounds';
import { useThreeOrthoObject } from '~/composables/useThreeOrthoObject';
import { LAYER_BG } from '~/core/constants';

const OrthographicWrapper = defineComponent({
    props: {
        visible: { default: true },
        name: { default: 'OrthographicWrapper' },
    },

    setup(props) {
        const { object } = useThreeOrthoObject(null, { name: props.name });
        // const mesh = new Mesh(simplePlaneGeometry, simpleMaterial());
        // object.add(mesh);
        // mesh.layers.set(LAYER_BG);

        useBounds(({ x, y, width, height }) => {
            object.position.set(x, y, 0);
            // mesh.scale.set(width, height, 1);
        });

        watchEffect(() => {
            object.visible = props.visible;
        });
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default OrthographicWrapper;
